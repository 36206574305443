import React from "react";
import Particles from "react-particles-js";



function pickAnimation(color) {
  console.log(color)

    return {
      "particles": {
        "number": {
          "value": Math.floor(Math.random() * 4 + 3),
          "density": {
            "enable": false,
            "value_area": 50000
          }
        },
        "color": {
          "value": color
        },
        "shape": {
          "type": "circle",
          "stroke": {
            "width": 0,
            "color": "#000000"
          },
          "polygon": {
            "nb_sides": 5
          },
          "image": {
            "src": "img/github.svg",
            "width": 10,
            "height": 10
          }
        },
        "opacity": {
          "value": 0.5,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 0.1,
            "opacity_min": 0.1,
            "sync": false
          }
        },
        "size": {
          "value": 2.5,
          "random": true,
          "anim": {
            "enable": false,
            "speed": 0.5,
            "size_min": 0.1,
            "sync": false
          }
        },
        "line_linked": {
          "enable": true,
          "distance": 6000,
          "color": color,
          "opacity": 0.25,
          "width": 1
        },
        "move": {
          "enable": true,
          "speed": 1.5,
          "direction": "none",
          "random": true,
          "straight": false,
          "out_mode": "out",
          "bounce": true,
          "attract": {
            "enable": true,
            "rotateX": 500,
            "rotateY": 500
          }
        }
      },
      "interactivity": {
        "detect_on": "window",
        "events": {
          "onhover": {
            "enable": true,
            "mode": "grab"
          },
          "onclick": {
            "enable": false,
            "mode": "push"
          },
          "resize": true
        },
        "modes": {
          "grab": {
            "distance": 350,
            "line_linked": {
              "opacity": 1
            }
          },
          "bubble": {
            "distance": 400,
            "size": 40,
            "duration": 0.5,
            "opacity": 8,
            "speed": 3
          },
          "repulse": {
            "distance": 70,
            "duration": 0.4
          },
          "push": {
            "particles_nb": 4
          },
          "remove": {
            "particles_nb": 4
          }
        }
      },
      "retina_detect": true
    }

  }
  

export default ({color}) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      position: 'fixed',
      backgroundColor: 'black',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundPepeat: 'noRepeat',
      zIndex: -1
    }}
  >
    <Particles
      params={pickAnimation(color)}
    />
  </div>
);

